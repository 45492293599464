<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center mb-10 my-8">
      <v-col cols="1">
        <v-btn icon rounded @click="$router.go(-1)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dense v-bind="attrs" v-on="on">
                <v-icon size="28">mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Volver</span>
          </v-tooltip>
        </v-btn>
      </v-col>
      <v-col cols="11" class="d-flex justify-center">
        <h1 class="justify-center">Especies y razas</h1>
      </v-col>
    </div>
    <!-- Información principal -->
    <div v-if="licen.Var4 == 1 && licen.Var5 == 1">
      <v-row>
        <!-- Especies -->
        <v-col xs="12" sm="12" md="12" lg="6" xl="6">
          <v-card width="100%" class="pa-3 mb-5 ma-2 rounded-xl">
            <v-card-title class="mt-4 ml-10 d-flex justify-space-between">
              <h2>Especies</h2>
              <v-btn @click="addAnimalTypeEspecie = true" color="primary"
                class="text-capitalize rounded-lg chip_button">Agregar</v-btn>
            </v-card-title>
            <div v-if="addAnimalTypeEspecie" class="d-flex pa-3">
              <v-text-field class="mr-2" outlined v-model="especie.Nombre" dense label="Nombre especie"></v-text-field>
              <!-- Opciones desplegadas al presionar "agregar" en el título -->
              <v-btn :disabled="activeUploadingCreateEspecie || !especie.Nombre" :loading="activeUploadingCreateEspecie"
                @click="btnSaveEspecie" icon color="primary"
                class="text-capitalize mr-2 rounded-lg"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
              <v-btn @click="addAnimalTypeEspecie = false" icon class="text-capitalize chip_button rounded-lg"
                color="primary"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <!-- Tarjeta de especies -->
            <div class="pa-3">
              <v-text-field v-model="searchEspecie" append-icon="mdi-magnify" placeholder="Buscar" single-line
                hide-details class="mb-1"></v-text-field>
            </div>
            <div v-if="activeUpdateNombreEspecie" class="d-flex pa-2">
              <v-text-field outlined dense label="Raza" v-model="updateNombreEspecie" class="mr-2"></v-text-field>
              <v-btn icon color="primary" :loading="activeUploadingUpdateEspecie"
                class="text-capitalize mr-2 rounded-lg"
                @click="btnUpdateEspecie"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
              <v-btn @click="activeUpdateNombreEspecie = false" icon class="text-capitalize chip_button rounded-lg"
                color="primary"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <v-data-table :headers="headersEspecie" :items="especies" prepend-icon="mdi-arrow-right"
              :search="searchEspecie" :footer-props="{
          'items-per-page-text': 'Filas por página',
        }">
              <template v-slot:no-data>
                <v-alert :value="true">No hay resultados disponibles</v-alert>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="actions">
                  <v-btn icon @click="btnSelectUpdateNombreEspecie(item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="activeUploadingDeleteEspecie" @click="btnSelecteDeleteEspecie(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!-- Razas -->
        <v-col xs="12" sm="12" md="12" lg="6" xl="6">
          <v-card width="100%" class="pa-3 mb-5 ma-2 rounded-xl">
            <!-- titulo y accion de agregar -->
            <v-card-title class="mt-4 ml-10 d-flex justify-space-between">
              <h2>Razas</h2>
              <v-btn @click="addAnimalType = true" color="primary"
                class="text-capitalize rounded-lg chip_button">Agregar</v-btn>
            </v-card-title>
            <div v-if="addAnimalType" class="d-flex">
              <v-text-field class="mr-2" outlined v-model="raza.Nombre" dense label="Nombre raza"></v-text-field>
              <!-- Opciones desplegadas al presionar "agregar" en el título -->
              <v-btn :disabled="!raza.Nombre || activeUploadingCreateRaza" :loading="activeUploadingCreateRaza"
                @click="btnSaveRaza" icon color="primary"
                class="text-capitalize mr-2 rounded-lg"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
              <v-btn @click="addAnimalType = false" icon class="text-capitalize chip_button rounded-lg"
                color="primary"><v-icon>mdi-close</v-icon></v-btn>
            </div>

            <div>
              <div class="pa-3">
                <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="Buscar" single-line hide-details
                  class="mb-1"></v-text-field>
              </div>
              <!-- actualizar  -->

              <div v-if="activeUpdateNombreRaza" class="d-flex pa-2">
                <v-text-field outlined dense label="Raza" v-model="updateNombreRaza" class="mr-2"></v-text-field>
                <v-btn icon color="primary" :loading="activeUploadingUpdateRaza" class="text-capitalize mr-2 rounded-lg"
                  @click="btnUpdateRaza"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
                <v-btn @click="activeUpdateNombreRaza = false" icon class="text-capitalize chip_button rounded-lg"
                  color="primary"><v-icon>mdi-close</v-icon></v-btn>
              </div>

              <v-data-table :headers="headers" :items="razas" :search="search" :footer-props="{
          'items-per-page-text': 'Filas por página',
        }">
                <template v-slot:no-data>
                  <v-alert :value="true">No hay resultados disponibles</v-alert>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="actions">
                    <v-btn icon @click="btnSelectUpdateNombreRaza(item)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn icon :loading="activeUploadingDeleteRaza" @click="btnSelectDeleteRaza(item)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
    <v-snackbar color="primary" v-model="successCreateEspecie" multiLine>
      Especie creada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successUpdateEspecie" multiLine>
      Especie actualizada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successDeleteEspecie" multiLine>
      Especie eliminada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successCreateRaza" multiLine>
      Raza creada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successUpdateRaza" multiLine>
      Raza actualizada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successDeleteRaza" multiLine>
      Raza eliminada con éxito
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // Función añadir tipo de animal
      addAnimalType: false,
      // Función añadir especie
      addAnimalTypeEspecie: false,
      especie: {
        Nombre: "",
      },
      raza: {
        Nombre: "",
        Foto: "0",
      },
      updateRazaId: "",
      activeUpdateNombreRaza: false,
      activeUpdateNombreEspecie: false,
      updateNombreEspecie: "",
      updateEspecieId: "",
      updateNombreRaza: "",
      // Nombres de razas
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "Nombre",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      // Nombres de especie
      headersEspecie: [
        {
          text: "Nombre",
          align: "start",
          value: "Nombre",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      activeUploadingCreateEspecie: false,
      activeUploadingCreateRaza: false,
      activeUploadingUpdateRaza: false,
      activeUploadingDeleteRaza: false,
      activeUploadingUpdateEspecie: false,
      activeUploadingDeleteEspecie: false,
      search: "",
      searchEspecie: "",
    };
  },
  watch: {
    successCreateEspecie: function (val) {
      if (val) {
        this.especie.Nombre = "";
        this.addAnimalTypeEspecie = false;
        this.activeUploadingCreateEspecie = false;
      }
    },
    successCreateRaza: function (val) {
      if (val) {
        this.raza.Nombre = "";
        this.addAnimalType = false;
        this.activeUploadingCreateRaza = false;
      }
    },
    successUpdateRaza: function (val) {
      if (val) {
        this.activeUploadingUpdateRaza = false;
        this.activeUpdateNombreRaza = false;
      }
    },
    successDeleteRaza: function (val) {
      if (val) {
        this.activeUploadingDeleteRaza = false;
      }
    },
    successUpdateEspecie: function (val) {
      if (val) {
        this.activeUploadingUpdateEspecie = false;
        this.activeUpdateNombreEspecie = false;
      }
    },
    successDeleteEspecie: function (val) {
      if (val) {
        this.activeUploadingDeleteEspecie = false;
      }
    },
  },
  computed: {
    ...mapState("customers-module", [
      "especies",
      "razas",
      "successCreateEspecie",
      "successCreateRaza",
      "successUpdateRaza",
      "successDeleteRaza",
      "successDeleteEspecie",
      "successUpdateEspecie",
    ]),
    ...mapState("auth-module", ["licen"]),
  },
  methods: {
    ...mapActions("customers-module", [
      "postEspecie",
      "postRaza",
      "updateRaza",
      "deleteRaza",
      "deleteEspecie",
      "updateEspecie",
    ]),
    btnSaveEspecie() {
      this.activeUploadingCreateEspecie = true;
      this.postEspecie(this.especie);
    },
    btnSaveRaza() {
      this.activeUploadingCreateRaza = true;
      this.postRaza(this.raza);
    },
    btnSelectUpdateNombreRaza(raza) {
      this.activeUpdateNombreRaza = true;
      this.updateNombreRaza = raza.Nombre;
      this.updateRazaId = raza.Id;
    },

    btnUpdateRaza() {
      this.activeUploadingUpdateRaza = true;
      this.updateRaza({
        raza: { Id: this.updateRazaId, Nombre: this.updateNombreRaza },
      });
    },
    btnSelectUpdateNombreEspecie(especie) {
      this.activeUpdateNombreEspecie = true;
      this.updateNombreEspecie = especie.Nombre;
      this.updateEspecieId = especie.Id;
    },
    async btnSelectDeleteRaza(raza) {
      if (window.confirm("¿Está seguro de eliminar la raza " + raza.Nombre + "?")) {
        this.activeUploadingDeleteRaza = true;
        await this.deleteRaza({ id: raza.Id });
      }
    },
    async btnUpdateEspecie() {
      this.activeUploadingUpdateEspecie = true;
      await this.updateEspecie({
        especie: { Id: this.updateEspecieId, Nombre: this.updateNombreEspecie },
      });
    },
    async btnSelecteDeleteEspecie(especie) {
      if (window.confirm("¿Está seguro de eliminar la especie " + especie.Nombre + "?")) {
        this.activeUploadingDeleteEspecie = true;
        await this.deleteEspecie({ id: especie.Id });
      }
    },

  },
};
</script>
<style>
/* Estilos a botones */
.chip_button {
  border-color: #0d47a1;
}

/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}
</style>
